import { inject, Injectable, signal } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserPortalNavigationService {

  private router = inject(Router);
  public isNavigationAppOn = signal<boolean>(true);
  public isAppInitializing = signal<boolean>(false);


  constructor() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isNavigationAppOn.set(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.isNavigationAppOn.set(false);
        this.isAppInitializing.set(false)
      } else {
        return
      }
    });
  }

  setAppInitilization(state: boolean) {
    this.isAppInitializing.set(state);
  }

  get initApp() {
    return this.isAppInitializing.asReadonly();
  }
}

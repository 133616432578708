import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwiperComponent
  implements AfterViewChecked, AfterViewInit, OnDestroy {
  // add all the options as optional settings and use them to create an options object
  @Input() config!: SwiperOptions;
  @Input() set initialize(value: boolean) {
    /*istanbul ignore next*/
    this.shouldInitialize = this.initialized ? false : value;
  }

  swiper!: Swiper;

  private swiperWrapper: any;
  private slideCount = 0;
  private readonly initialized = false;
  private shouldInitialize = true;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    /*istanbul ignore if*/
    if (this.shouldInitialize) {
      this.setup();
    }
  }

  setup() {
    if (!this.swiper) {
      // if rendered on server querySelector is undefined
      if (this.elementRef.nativeElement.querySelector) {
        this.swiperWrapper = this.elementRef.nativeElement.querySelector(
          '.swiper-wrapper'
        );
        this.slideCount = this.swiperWrapper.childElementCount;
        this.swiper = new Swiper(
          this.elementRef.nativeElement.querySelector('swiper > div'),
          this.config
        );
        this.changeDetectorRef.detectChanges();
      }

      this.shouldInitialize = false;
    }
  }

  ngAfterViewChecked() {
    if (this.shouldInitialize) {
      /*istanbul ignore next*/
      this.setup();
    }

    /*istanbul ignore if*/
    if (
      this.swiperWrapper &&
      this.slideCount !== this.swiperWrapper.childElementCount
    ) {
      this.slideCount = this.swiperWrapper.childElementCount;
      this.swiper.update();
    }
  }

  ngOnDestroy() {
    this.swiper.destroy(true, true);
  }
}
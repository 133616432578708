import { LoginResponse } from "angular-auth-oidc-client/lib/login/login-response";
import { AuthenticationService } from "./features/authentication/services/authentication.service";
import { Component, HostListener, inject, OnDestroy, OnInit } from "@angular/core";
import { AzureService } from "./core/services/azure.service";
import { cleanSessionStorage, retrieveDecryptedFromSessionStorage, saveEncryptedToSessionStorage, verifySessionItem } from "./shared/helpers/sessionStorage.helper";
import { UserPortalNavigationService } from "./shared/services/user-portal-navigation/user-portal-navigation.service";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "User Portal";

  private azureService = inject(AzureService);
  private authenticationService = inject(AuthenticationService);
  private readonly userPortalNavigationService = inject(UserPortalNavigationService);

  isLoadingAppData = this.userPortalNavigationService.isAppInitializing;
  logo: string = 'assets/images/lcptracker-full-color.svg';
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  diameter = 30;

  constructor() {
    this.authenticationService.checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        const { isAuthenticated, userData, accessToken, idToken, configId, errorMessage } = loginResponse;
        this.authenticationService.fortifyToken.next(accessToken);
      });

    // this.authenticationService.sessionStateFromIDPListener();
    this.authenticationService.refreshTokenEventListener();
    this.authenticationService.IsAuthenticatedAlert();
    this.authenticationService.getUserDataFromFortify();
  }

  ngOnInit(): void {
    if (verifySessionItem('contentful')) {
      const contentful = retrieveDecryptedFromSessionStorage('contentful');
      this.azureService.contentSubject.next(contentful as string);
      cleanSessionStorage(['contentful']);
    }
    if (verifySessionItem('contentSpace')) {
      const contentSpace = retrieveDecryptedFromSessionStorage('contentSpace');
      this.azureService.spaceSubject.next(contentSpace as string);
      cleanSessionStorage(['contentSpace']);
    }

    if (verifySessionItem('emailCredentials')) {
      const emailCredentials = retrieveDecryptedFromSessionStorage('emailCredentials') as string;
      this.azureService.supportConsoleEmailSubject.next(JSON.parse(emailCredentials));
      cleanSessionStorage(['emailCredentials']);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event): void {
    if (this.azureService.contentSubject.value != '') {
      saveEncryptedToSessionStorage('contentful', this.azureService.contentSubject.value);
    }
    if (this.azureService.spaceSubject.value != '') {
      saveEncryptedToSessionStorage('contentSpace', this.azureService.spaceSubject.value);
    }
    if (this.azureService.emailCredentialsValue != null) {
      saveEncryptedToSessionStorage('emailCredentials', JSON.stringify(this.azureService.emailCredentialsValue));
    }

  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot,
} from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { DashboardContentfulPage } from '../graphql/dashboard-data.query';
import { CsoPaymentService } from '../../payment-cso/services/cso-payment.service';

export const dashboardContentResolver: ResolveFn<DashboardContentfulPage> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const csoPaymentService = inject(CsoPaymentService);
  const dashboardService = inject(DashboardService);

  // Retrieve signal values
  const isCsoEnabled = csoPaymentService.csoStatusEnabled(); // CSO status signal
  const hasValidLicense = csoPaymentService.hasValidLicense(); // License validity signal
  const isContractor = csoPaymentService.isContractor(); // Contractor status signal

  // Case 4: If CSO is disabled -> Return slider content
  if (!isCsoEnabled) {
    return dashboardService.fetchDashboardPage('cache-first', false);
  }

  // Case 1: Contractor without a valid license -> Return promotional content
  if (isContractor && !hasValidLicense) {
    const nonPromotionalContent = await dashboardService.fetchDashboardPage('cache-first', false);
    const promotionalContent = await dashboardService.fetchDashboardPage('cache-first', true);
    
    // Merge the two datasets (nonPromotionalContent and promotionalContent)
    return {
      ...nonPromotionalContent,
      lcpCarouselCollection: {
        items: [
          ...promotionalContent.lcpCarouselCollection.items,
          ...nonPromotionalContent.lcpCarouselCollection.items,
        ],
      },
    };
  }

  // Case 2: Contractor with a valid license -> Return slider content
  // Case 3: Non-contractor -> Return slider content
  return dashboardService.fetchDashboardPage('cache-first', false);
}

export const profileUserContentResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(DashboardService).fetchProfileCardInfo('cache-first');
}

export const appTilesContentResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(DashboardService).fetchAppTiles('cache-first');
}

import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CsoPaymentService } from '../../payment-cso/services/cso-payment.service';
import { GetUserDataByIDService } from 'src/app/shared/layouts/portal/services/get-user-data-by-id.service';
import { cleanSessionStorage, retrieveDecryptedFromSessionStorage, saveEncryptedToSessionStorage } from 'src/app/shared/helpers';

export const CheckLcpPlusWorkflowGuard: CanActivateFn = async () => {
  const csoPaymentService = inject(CsoPaymentService);
  const getUserDataByIDService = inject(GetUserDataByIDService);

  try {
    // Step 1: Check if LCP+ workflow is already validated
    const lcpChecked = retrieveDecryptedFromSessionStorage('LCPlusChecked');
    if (lcpChecked) {
      cleanSessionStorage(['LCPlusChecked']);
      return true;
    }

    // Step 2: Verify CSO app status
    const csoStatus = await csoPaymentService.getCSOStatus('network-only');
    const isCsoEnabled = csoStatus.toLocaleLowerCase() === 'enabled';
    csoPaymentService.csoStatusEnabled.set(isCsoEnabled);

    if (!isCsoEnabled) {
      return true; // Stop further processing but allow route activation
    }

    // Step 3: Fetch current organizations
    const currentOrgs =  getUserDataByIDService.getContractorsValue;
    let contractorOrgs: any[] = [];

    if (!Array.isArray(currentOrgs)) {
      if (currentOrgs?.org.orgType === 'CONTRACTOR') {
        contractorOrgs = [currentOrgs];
      }
    } else {
      contractorOrgs = currentOrgs.filter((org) => org.org.orgType === 'CONTRACTOR');
    }

    const isContractor = contractorOrgs.length > 0;
    csoPaymentService.isContractor.set(isContractor);

    if (!isContractor) {
      return true; // Stop further processing but allow route activation
    }


    // Step 4: Check for valid licenses
    const contractorOrgIds = contractorOrgs.map((org) => org.org.id);
    const licensesResponse = await csoPaymentService.getTypeOfLicensesByOrgIds(contractorOrgIds, 'network-only');
    const hasLicense = licensesResponse.length > 0;
    csoPaymentService.hasValidLicense.set(hasLicense);

    if (licensesResponse[0] && typeof licensesResponse[0].value === 'object') {
      csoPaymentService.licenseType.set(licensesResponse[0].value.LicType as 'monthly' | 'annual' | 'none');
    }

    // Always return true to allow route activation
    return true;
  } catch (error) {
    console.error('Error during LCP+ workflow initialization:', error);

    // Update signals to reflect an error state
    csoPaymentService.csoStatusEnabled.set(false);
    csoPaymentService.isContractor.set(false);
    csoPaymentService.hasValidLicense.set(false);

    // Allow route activation despite the error
    return true;
  }
};

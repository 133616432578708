import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { AuthenticationService } from "src/app/features/authentication/services/authentication.service";
import { Org } from "src/app/features/dashboard/graphql/profile-info.query";
import { DataClaim } from "src/app/features/payment-cso/graphql/mutations/add-agreement-claim-to-org.query.graphql";
import { Agreement } from "src/app/features/payment-cso/graphql/verify-lic-agreement-user-in-org.query.graphql";
import { CsoPaymentService } from "src/app/features/payment-cso/services/cso-payment.service";
import { getTargetUrl, formatDateTimeToLocaleString, retrieveDecryptedFromLocalStorage, cleanLocalStorage } from "src/app/shared/helpers";
import { UserPortalDialogService } from "src/app/shared/services/dialog/user-portal-dialog.service";
import { GetUserDataByIDService } from "../services/get-user-data-by-id.service";
import { UserServicesAgreementDialogComponent } from "./user-services-agreement-dialog/user-services-agreement-dialog.component";
import { BasicContractorAndORgInformation } from "./interfaces/user-services-agreements";
@Component({
  selector: "app-user-services-agreement",
  template: ``,
  styles: [],
})
export class UserServicesAgreementComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    private csoInfo: CsoPaymentService,
    private userPortalDialogService: UserPortalDialogService,
    private userService: GetUserDataByIDService,
    private dialog: MatDialog,

  ) { }

  organizations: Org[] | Org = [];
  contractors: Org[] = [];
  licenseValue = false;
  contractorsOrgId: string[] = [];
  userInOrg: string[] = [];
  orgsWithLicense?: string[] = [];
  userInOrgIdsData: string[] = [];
  formattedDate: string = "";
  userAgreementsData: string[] = [];
  purchaseValue: string = '';
  contractorOrgIdAndOrgName: BasicContractorAndORgInformation[] = [];

  private unsubscribeAll = new Subject<void>();
  private purchaseDataSubscription?: Subscription;

  ngOnInit(): void {
    if (!this.csoInfo.csoStatusEnabled()) {
      return
    }
    if (getTargetUrl() === "/cso/verify") {
      this.getAllOrgsFromUser();
      this.extractContractorData();
      this.setPurchaserAgreements();
      this.verifyLicensesInContractors();
    }
  }

  /* istanbul ignore next */
  getAllOrgsFromUser(): void {
    this.userService.getContractors
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (response) => {
          this.organizations = response;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  private extractContractorData(): void {
    // Step 1: Ensure `this.organizations` exists and is an array before proceeding.
    if (!Array.isArray(this.organizations)) {
      return;
    }

    // Step 2: Filter only organizations of type "CONTRACTOR".
    this.contractors = this.organizations.filter((orgs: Org) => orgs.org.orgType === "CONTRACTOR");

    // Step 3: Extract necessary information (orgId, orgName, userInOrgId) from contractor organizations.
    this.contractorOrgIdAndOrgName = this.contractors.map((item) => ({
      orgId: item.org.id,
      orgName: item.org.orgName,
      userInOrg: item.userInOrgId,
    }));

    // Step 4: Extract only the organization IDs from the contractors.
    this.contractorsOrgId = this.contractors.map((orgElements: Org) => orgElements.org.id);
  }


  async verifyLicensesInContractors(): Promise<void> {
    const invoiceCheckedByUser = retrieveDecryptedFromLocalStorage('invoice-checked');
    if (invoiceCheckedByUser === 'true') {
      cleanLocalStorage(['invoice-checked']);
      return
    }
    try {
      // Step 5: Fetch license information for the extracted contractor organization IDs.
      const LicenseResponse = await this.csoInfo.getTypeOfLicensesByOrgIds(this.contractorsOrgId);

      // Step 6: Extract parentId values from the license response to determine which organizations have a valid license.
      this.orgsWithLicense = LicenseResponse.map(
        /* istanbul ignore next */
        (license) => license.parentId
      );

      // Step 7: Filter out only those contractors that have a valid license.
      /* istanbul ignore next */
      this.contractorOrgIdAndOrgName = this.contractorOrgIdAndOrgName.filter(
        (item: BasicContractorAndORgInformation) => this.orgsWithLicense?.includes(item.orgId)
      );

      // Step 8: If any licenses are returned, set `licenseValue` to true.
      /* istanbul ignore next */
      if (LicenseResponse.length) {
        this.licenseValue = true;
      }

      // Step 9: Proceed to verify agreements based on the user and organization IDs.
      /* istanbul ignore next */
      await this.verifyAgreementsByUserAndOrgIds();
    } catch (error) {
      // Step 10: Handle any errors gracefully and log them to the console.
      /* istanbul ignore next */
      console.error("An error has occurred: ", error);
    }
  }


  /* istanbul ignore next */
  async verifyAgreementsByUserAndOrgIds(): Promise<void> {
    // Step 1: If `licenseValue` is false, exit the function early since no verification is needed.
    if (!this.licenseValue) {
      return;
    }

    // Step 2: Filter only contractors that have a valid license by matching `org.id` with `orgsWithLicense`.
    const licensedContractors = this.contractors.filter((item) => {
      return this.orgsWithLicense?.some((data) => item.org.id === data);
    });

    // Step 3: Extract `userInOrgId` from all contractors.
    this.userInOrgIdsData = this.contractors.map((user) => user.userInOrgId);

    // Step 4: Fetch agreements accepted by users in the organizations.
    const agreementsAcceptedByUserInOrg = await this.csoInfo.getUserServicesAgreement(this.userInOrgIdsData);

    // Step 5: If no agreements are found for any user, update `userInOrgIdsData` to include only contractors with a valid license and open the agreement dialog.
    if (!agreementsAcceptedByUserInOrg.length) {
      this.userInOrgIdsData = licensedContractors.map((value) => value.userInOrgId);
      this.openAgreementDialog();
      return;
    }

    // Step 6: If agreements exist, filter the users by comparing licenses with agreements.
    this.userInOrgIdsData = licensedContractors.map((value) => value.userInOrgId);
    this.userAgreementsData = this.compareLicenseWithAgreements(
      this.userInOrgIdsData,
      agreementsAcceptedByUserInOrg
    );

    // Step 7: If there are no agreements found for the filtered users, exit the function.
    if (!this.userAgreementsData[0]) {
      return;
    }

    // Step 8: If agreements exist, update `userInOrgIdsData` and open the agreement dialog.
    this.userInOrgIdsData = this.userAgreementsData;
    this.openAgreementDialog();
  }

  async acceptTermsAndConditions(): Promise<void> {
    // Step 1: Get the selected users who need to accept terms and conditions.
    const userInOrgSelected = this.userInOrgIdsData;

    // Step 2: If there are users selected, iterate over them and attempt to add agreements for each.
    if (userInOrgSelected.length > 0) {
      for (const userInOrg of userInOrgSelected) {
        try {
          // Step 3: Attempt to add agreement for the user.
          await this.addAgreementForUser(userInOrg);
        } catch (error) {
          // Step 4: Log any errors encountered during the agreement process.
          console.error(error);
        }
      }
    } else {
      // Step 5: If no users were selected, log a message.
      console.log('No users selected.');
    }
  }

  /* istanbul ignore next */
  private async addAgreementForUser(userInOrgId: string): Promise<void> {

    const addAgreementParameters: DataClaim = {
      parentId: userInOrgId,
      key: "CSO_AcceptedUserLic",
      value: "true - User has accepted the new agreement.",
      isPublic: false,
      dateStart: formatDateTimeToLocaleString(-1440),
      dateExpire: formatDateTimeToLocaleString(5256000)
    };

    try {
      await this.csoInfo.addUserAgreements(addAgreementParameters);
    } catch (error) {
      console.error(error);
    }
  }
  /* istanbul ignore next */
  openAgreementDialog(): void {
    let userAgreementsInfo: BasicContractorAndORgInformation[] = [];
    if (!this.userAgreementsData.length && this.contractorOrgIdAndOrgName.length) {
      userAgreementsInfo = this.contractorOrgIdAndOrgName;

    } else {
      userAgreementsInfo = this.contractorOrgIdAndOrgName.filter(
        (item: any) => {
          return this.userAgreementsData.some(
            (agreement) => agreement === item.userInOrg
          );
        }
      );
    }

    const agreementConfirmationDialogRef = this.dialog.open(
      UserServicesAgreementDialogComponent,
      { disableClose: true, data: userAgreementsInfo }
    );

    agreementConfirmationDialogRef.afterClosed().subscribe(async (value) => {
      if (!value) {
        this.showDeclineConfirmationDialog();
      } else {
        await this.acceptTermsAndConditions();
      }
    });
  }

  showDeclineConfirmationDialog(): void {
    const dialogRef = this.userPortalDialogService.userPortalDialog(
      'Decline User Services Agreement',
      `<p>
      By declining the User Services Agreement, you will be logged out. 
      <br/> <br/> Are you sure you want to log out?
      </p>`,
      'Log Out',
      'Back',
    );
    dialogRef.afterClosed().subscribe({
      next: response => {
        if (response) {
          this.showLoadingLogoutDialog();
          this.logout();
        } else {
          this.openAgreementDialog();
        }
      }
    })
  }

  setPurchaserAgreements(): void {
    if (!this.csoInfo._purchaseInformation.value) {
      return;
    }
    this.csoInfo.getPurchaseDataSubject
      .pipe(takeUntil(this.unsubscribeAll), take(1))
      .subscribe({
        next: (response) => {
          if (response?.createClaim?.data?.parentId) {
            this.purchaseValue = response.createClaim.data.parentId;

            const orgArray = this.contractors.find((item) => {
              return item.org.id === this.purchaseValue;
            });

            this.addAgreementForUser(orgArray?.userInOrgId!);
          }
        },
        error: (error) => {
          console.error(error);
        }
      })
  }

  showLoadingLogoutDialog(): void {
    this.userPortalDialogService.userPortalDialog(
      'Logging Out',
      `<p>
        Closing Session...
      </p>`,
      null,
      null,
      '430px',
      true
    );
  }
  /* istanbul ignore next */
  ngOnDestroy(): void {
    if (this.purchaseDataSubscription) {
      this.purchaseDataSubscription.unsubscribe();
    }
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }


  // helper methods
  /* istanbul ignore next */
  logout(): void {
    this.authenticationService.logout();
  }

  compareLicenseWithAgreements(idsUserInOrgLicenses: string[], agreements: Agreement[]): string[] {
    const missingAgreementIds: string[] = [];
    idsUserInOrgLicenses.forEach((userInOrgId) => {
      const hasAgreement = agreements.some((agreement) => agreement.parentId === userInOrgId);
      if (!hasAgreement) {
        missingAgreementIds.push(userInOrgId);
      }
    });
    return missingAgreementIds;
  }
}
@if(isLoadingAppData()){
<div class="flex items-center justify-center gap-1.14 w-full h-full">
  <img [src]="logo" alt="LCPtracker Logo" width="165px" height="auto">
  <div class="pt-1.14 gap-1.14">
      <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" [diameter]="diameter">
      </mat-progress-spinner>
  </div>
</div>
}
<router-outlet></router-outlet>

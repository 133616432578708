import { CanActivateFn } from '@angular/router';
import { GetUserDataByIDService } from '../services/get-user-data-by-id.service';
import { environment } from 'src/environments/environment';
import { LoginHistoryService } from 'src/app/features/support-console/services/login-history.service';
import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { inject } from '@angular/core';
import { AuthCanaryService } from 'src/app/shared/services/auth-canary/auth-canary.service';
import { UserPortalNavigationService } from 'src/app/shared/services/user-portal-navigation/user-portal-navigation.service';
import { cleanSessionStorage, retrieveDecryptedFromSessionStorage } from 'src/app/shared/helpers';

/**
 * A guard function that ensures the user's data is validated before accessing the application.
 *
 * This function performs the following:
 * 1. Checks if the user is coming from a callback redirect and sets the app initialization state.
 * 2. Fetches user data from RBS (Role-Based System) and validates their session type (e.g., SSO).
 * 3. Evaluates if the user's password is expired and redirects them to the password reset flow if necessary.
 * 4. Validates Canary status for users and organizations in non-production environments.
 *
 * @returns {Promise<boolean>} Resolves `true` if the user's data is valid and access is granted, otherwise `false`.
 */
export const userDataGuard: CanActivateFn = async () => {
  const getUserDataByIDService = inject(GetUserDataByIDService);
  const loginHistoryService = inject(LoginHistoryService);
  const authenticationService = inject(AuthenticationService);
  const authenticationCanaryService = inject(AuthCanaryService);
  const _userPortalNavigationService = inject(UserPortalNavigationService);

  try {
    // Check if the user is coming from a callback redirect
    const comingFromCallback = retrieveDecryptedFromSessionStorage('CallbackRedirect');
    if (!comingFromCallback) {
      _userPortalNavigationService.setAppInitilization(true);
    }

    // Retrieve the current user's username and session data
    const userName = authenticationService.userName;
    const responseFromRBS = await getUserDataByIDService.fetchUserDataFromRBSByID('network-only');
    const lastSession = await loginHistoryService.getLastSessionMethod('network-only', userName.value);

    // Evaluate the authentication type of the user
    if (lastSession.edges[0].node.authType === "SSO") {
      return responseFromRBS ? true : false;
    } else if (responseFromRBS.isPasswordExpired) {
      // Redirect the user if their password is expired
      document.location.href = environment.onboardingClaims.password;
      return false;
    }

    /* NOTE: Commented code until canary feature is required */
    // Retrieve user ID and contractor organizations
    // const userId = authenticationService.userId;
    // const orgs = getUserDataByIDService.getContractorsValue;
    // Check Canary status for the user and organizations in non-production environments
    // if (environment.env !== 'prod') {
    //   await authenticationCanaryService.verifyUserCanary(userId.value);
    //   await authenticationCanaryService.verifyOrgCanary(orgs);
    // }

    return responseFromRBS ? true : false;

  } catch (error) {
    // Handle errors during user data retrieval and validation
    console.error('Error fetching user data:', error);
    return false;
  }
};
